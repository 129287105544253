<template>
  <div>
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
    </div>
    <div v-else class="min-h-screen">
      <div class="flex items-center mb-6">
        <button @click="toLMSClassListMenu()" class="mr-3 w-8 h-8 flex justify-center items-center focus:outline-none">
          <ArrowForward direction="left" />
        </button>
        <h1 class="text-3xl font-bold flex items-end gap-2">
          {{ modeForm }} Class Schedule
          <p class="font-normal inline-block">(Webinar)</p>
        </h1>
      </div>
      <div class="tab flex justify-between items-end pl-4">
        <div class="flex gap-2">
          <div @click="selectTab('Detail')" class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer" :class="[selectedTab === 'Detail' ? 'font-bold bg-white' : 'bg-neutral-50']">
            Detail
          </div>
          <div
            v-show="isEditMode"
            @click="selectTab('Students')"
            class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
            :class="[selectedTab === 'Students' ? 'font-bold bg-white' : 'bg-neutral-50']"
          >
            Students
          </div>
          <div
            v-show="isEditMode && clientId == 1"
            @click="selectTab('Grade')"
            class="tab__item text-sm px-5 py-2 rounded-tr-lg rounded-tl-lg cursor-pointer"
            :class="[selectedTab === 'Grade' ? 'font-bold bg-white' : 'bg-neutral-50']"
          >
            Grade
          </div>
        </div>
        <div class="flex justify-end py-2">
          <Button v-if="selectedTab !== 'Grade'" :buttonText="isEditMode ? 'Update' : isCopyMode ? 'Copy' : 'Submit'" @action="submitData" size="compact" />
        </div>
      </div>
      <template v-if="selectedTab === 'Detail'">
        <div class="shadow-small rounded-lg bg-white">
          <div class="flex items-stretch shadow-border-b">
            <div class="p-6 w-1/2 shadow-border-r flex-shrink-0">
              <p class="text-lg font-semibold mb-4">General</p>
              <div class="mb-6" v-if="isEditMode || isCopyMode">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">ID</label>
                <TextField borderEnabled v-model="options.id" disabled />
              </div>
              <div class="relative mb-6">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Class<span class="text-red"> *</span></label>
                <div class="relative">
                  <TextField borderEnabled withIcon v-model="classKeyword" @input="searchClass()" placeholder="Search Class..." />
                  <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                    <Search />
                  </div>
                </div>
                <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearching">
                  <template v-if="list_kelas.length > 0 && !isFetching">
                    <div
                      @click="selectClass(item_kelas)"
                      class="py-1 px-3 text-white cursor-pointer hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                      v-for="item_kelas in list_kelas"
                      :key="item_kelas.id"
                    >
                      {{ item_kelas.nama }}
                    </div>
                  </template>
                  <template v-if="list_kelas.length === 0 && !isFetching">
                    <p class="py-1 px-3 text-white font-normal text-center">Class is not found.</p>
                  </template>
                </div>
              </div>
              <div class="mb-6">
                <TextField borderEnabled isLabelRequire label="Schedule Name" :disabled="!options.kelas_id" v-model="options['nama']" placeholder="Schedule Name" />
              </div>
              <div class="mb-6">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Instructor<span class="text-red"> *</span></label>
                <Dropdown
                  :options="list_instructur"
                  :disabled="!options.nama || !options.kelas_id"
                  optionLabel="name"
                  v-model="selectedInstructor"
                  :default="selectedInstructor"
                  placeholder="Select Instructor"
                />
              </div>
              <div class="mb-6">
                <TextField
                  :type="phoneInputType"
                  placeholder="08xxxx"
                  label="Customer Service Number"
                  v-model="options.customerServiceNumber"
                  maxlength="15"
                  autocomplete="off"
                  :borderEnabled="true"
                  :error="errors['customerServiceNumber']"
                />
              </div>
              <div class="mb-6">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Status<span class="text-red"> *</span></label>
                <Dropdown :options="statusOption" optionLabel="name" v-model="selectedStatus" :default="selectedStatus" placeholder="Select Status" />
              </div>
              <div class="mb-6">
                <TextField borderEnabled label="Batch" type="number" v-model="options['batch']" placeholder="Batch" />
              </div>
              <p class="text-lg font-semibold mb-4">Schedule</p>
              <div class="schedule">
                <Button buttonText="Add Schedule" @action="addSchedule" type="secondary" />
                <div class="mt-6 p-4 border rounded-lg" v-for="(itemSchedule, indexSchedule) in options['schedule']" v-bind:key="itemSchedule.field">
                  <div class="flex justify-between items-center mb-6">
                    <label class="font-semibold">Activity - {{ indexSchedule + 1 }}</label>
                    <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteSchedule(indexSchedule)" />
                  </div>
                  <div class="mb-6">
                    <TextField borderEnabled label="Title" type="text" v-model="options['schedule'][indexSchedule].title" placeholder="Enter Title" />
                  </div>
                  <div class="mb-6">
                    <TextField borderEnabled label="Description" type="text" v-model="options['schedule'][indexSchedule].description" placeholder="Enter Description" />
                  </div>
                  <div class="flex gap-6 items-center time mb-6">
                    <div class="w-1/2">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Start Time</label>
                      <datetime type="datetime" v-model="options['schedule'][indexSchedule].start_date" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm:ss"></datetime>
                    </div>
                    <div class="w-1/2">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">End Time</label>
                      <datetime type="datetime" v-model="options['schedule'][indexSchedule].end_date" zone="Asia/Jakarta" format="yyyy-MM-dd HH:mm:ss"></datetime>
                    </div>
                  </div>
                  <div>
                    <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Material</label>
                    <quill-editor v-model="options['schedule'][indexSchedule].materi" />
                  </div>
                </div>
              </div>
            </div>
            <div class="p-6 w-1/2 flex-shrink-0">
              <p class="text-lg font-semibold mb-4">Media</p>
              <div class="mb-6">
                <TextField borderEnabled label="WhatsApp Link" type="text" v-model="options['link_wa']" placeholder="Whatsapp Link" />
              </div>
              <div class="mb-6">
                <TextField borderEnabled label="Zoom Link" type="text" v-model="options['link_zoom']" placeholder="Zoom Link" />
              </div>
              <div class="mb-6">
                <TextField borderEnabled label="Host Key" type="text" v-model="options['host_key']" placeholder="Host Key" />
              </div>
              <p class="text-lg font-semibold mb-4">Exercise</p>
              <div class="mb-6">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Pretest</label>
                <Dropdown :options="list_exercise_pretest" optionLabel="name" v-model="selectedPretest" :default="selectedPretest" placeholder="Select Pretest" />
              </div>
              <div class="mb-6">
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Exam</label>
                <Dropdown :options="list_exercise_exam" optionLabel="name" v-model="selectedExam" :default="selectedExam" placeholder="Select Exam" />
              </div>
              <p class="text-lg font-semibold mb-4">Document</p>
              <div class="document">
                <Button buttonText="Add Document" @action="addDocument" type="secondary" />
                <div class="mt-6 p-4 border rounded-lg" v-for="(itemDocument, indexDocument) in options['document']" v-bind:key="itemDocument.field">
                  <div class="flex justify-between items-center mb-6">
                    <label class="font-semibold">Document - {{ indexDocument + 1 }}</label>
                    <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteDocument(indexDocument)" />
                  </div>
                  <div class="mb-6">
                    <TextField borderEnabled label="Name" type="text" v-model="options['document'][indexDocument].name" placeholder="Enter Document Name" />
                  </div>
                  <div class="mb-6">
                    <TextField borderEnabled label="Link" type="text" v-model="options['document'][indexDocument].file_url" placeholder="Enter Document Link" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="selectedTab === 'Students'">
        <div class="shadow-small rounded-lg bg-white flex">
          <div class="form p-6 transition-all duration-200" :class="[isAddStudentActive ? 'w-1/2' : 'w-full']">
            <div class="flex w-full justify-between mb-4 items-center border-b border-neutral-50 pb-4">
              <p class="font-semibold flex gap-1 items-center">
                <span class="-ml-1"><Label color="yellow" /></span>List of Students
              </p>
              <Button buttonText="Add Students" type="tertiary" v-if="!isAddStudentActive" @action="isAddStudentActive = !isAddStudentActive" />
            </div>
            <div>
              <div v-if="studentList.length > 0">
                <DataTable
                  :tableHeader="['Name', 'Phone Number']"
                  :tableHeaderAlign="['left', 'left']"
                  :list="studentList"
                  :listAlign="['left', 'left']"
                  :counter="counterStudent"
                  :totalPages="totalPagesStudent"
                  :totalItems="totalRowsStudent"
                  :size="sizeStudent"
                />
                <div v-if="totalRowsStudent > 10" class="mt-3 flex justify-center items-center">
                  <Pagination :currentPage="pageForPaginationStudent" :totalCount="totalRowsStudent" :pageSize="sizeStudent" @onPageChange="onPageChangeStudent" />
                </div>
              </div>
              <div v-else class="w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center"><p>No student available</p></div>
            </div>
          </div>
          <div class="form p-6 shadow-border-l w-1/2 transition-all duration-200" v-if="isAddStudentActive">
            <div class="flex justify-between items-center mb-4 border-b border-neutral-50 pb-4">
              <p class="font-semibold flex gap-1 items-center">
                <span class="-ml-1"><Label color="yellow" /></span>Add Students
              </p>
              <span class="cursor-pointer" @click="isAddStudentActive = !isAddStudentActive"><Close /></span>
            </div>
            <div>
              <div class="border rounded-lg p-5">
                <div class="mb-5">
                  <p class="mb-3 text-sm">1. Apply filter</p>
                  <div class="bg-neutral-100 rounded-lg p-3">
                    <div class="mb-6">
                      <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Source</label>
                      <Dropdown :options="source" v-model="selectedSource" :default="selectedSource" placeholder="Select Source" optionLabel="name" />
                    </div>
                    <div class="mb-6">
                      <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Program</label>
                      <Dropdown :disabled="isDisabled" :options="programs" v-model="selectedProgramStudent" :default="selectedProgramStudent" placeholder="Select Class Type" optionLabel="name" />
                    </div>
                    <div class="mb-6">
                      <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">
                        Status
                        <span v-show="selectedProgramStudent.value === ''" class="inline-block text-neutral-300">(Please select the Program first)</span>
                      </label>
                      <Dropdown
                        :disabled="isDisabled || selectedProgramStudent.value === ''"
                        :options="pipelineStage"
                        v-model="selectedPipelineStage"
                        :default="selectedPipelineStage"
                        placeholder="Select Status"
                        optionLabel="name"
                      />
                    </div>
                    <div class="flex items-center">
                      <Button class="mr-3" buttonText="Apply" type="secondary" @action="getProspectList()" />
                      <Button buttonText="Reset" type="tertiary" @action="resetFilterStudent()" />
                    </div>
                  </div>
                </div>
                <div>
                  <p class="mb-3 text-sm">2. Add student</p>
                  <div v-if="isFormRegisterVisible" class="bg-neutral-100 rounded-lg p-3">
                    <div class="mb-6">
                      <TextField placeholder="Enter name" label="Name" v-model="userFullName" autocomplete="off" borderEnabled :error="errors['userFullName']" />
                    </div>
                    <div class="mb-6">
                      <TextField placeholder="Enter email" label="Email" v-model="userEmail" autocomplete="off" borderEnabled :error="errors['userEmail']" />
                    </div>
                    <div class="mb-5">
                      <TextField
                        :type="phoneInputType"
                        placeholder="Enter phone number"
                        label="Phone Number"
                        v-model="userPhoneNumber"
                        maxlength="15"
                        autocomplete="off"
                        borderEnabled
                        :error="errors['userPhoneNumber']"
                      />
                    </div>
                    <div class="mb-3 flex justify-center">
                      <Button size="compact" buttonText="Add New Student" @action="addNewStudent" />
                    </div>
                  </div>
                  <div v-else class="bg-neutral-100 rounded-lg p-3">
                    <div class="relative mb-6">
                      <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Search</label>
                      <div class="relative">
                        <TextField @keyUpAction="searchProspect" :enterKeyAction="searchProspect" borderEnabled withIcon v-model="keyword" placeholder="Search name, email, phone number" />
                        <div class="absolute left-3 top-1/2 transform -translate-y-1/2">
                          <Search />
                        </div>
                      </div>
                    </div>
                    <div v-show="selectedProspect.length > 0" class="flex justify-between items-center">
                      <p class="text-sm">{{ selectedProspect.length }} selected students</p>
                      <Button buttonText="Add Selected Student" size="compact" @action="showAddStudentModal()" />
                    </div>
                    <div v-if="prospectList.length > 0">
                      <DataTable
                        :tableHeader="['Name', 'Phone Number']"
                        :tableHeaderAlign="['left', 'left']"
                        :checkbox="true"
                        :actionCheckbox="actionCheckbox"
                        :resetSelectedData="isReset"
                        :list="prospectList"
                        :listAlign="['left', 'left']"
                        :actionButtons="[
                          {
                            additionalStyle: 'p-1.5',
                            height: '20',
                            width: '20',
                            viewBox: '0 0 20 20',
                            paths: [
                              {
                                d: 'M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM13.75 10.75H10.75V13.75C10.75 13.9489 10.671 14.1397 10.5303 14.2803C10.3897 14.421 10.1989 14.5 10 14.5C9.80109 14.5 9.61032 14.421 9.46967 14.2803C9.32902 14.1397 9.25 13.9489 9.25 13.75V10.75H6.25C6.05109 10.75 5.86032 10.671 5.71967 10.5303C5.57902 10.3897 5.5 10.1989 5.5 10C5.5 9.80109 5.57902 9.61032 5.71967 9.46967C5.86032 9.32902 6.05109 9.25 6.25 9.25H9.25V6.25C9.25 6.05109 9.32902 5.86032 9.46967 5.71967C9.61032 5.57902 9.80109 5.5 10 5.5C10.1989 5.5 10.3897 5.57902 10.5303 5.71967C10.671 5.86032 10.75 6.05109 10.75 6.25V9.25H13.75C13.9489 9.25 14.1397 9.32902 14.2803 9.46967C14.421 9.61032 14.5 9.80109 14.5 10C14.5 10.1989 14.421 10.3897 14.2803 10.5303C14.1397 10.671 13.9489 10.75 13.75 10.75Z',
                                fill: '#3b82f6'
                              }
                            ],
                            next: addStudent
                          }
                        ]"
                        :counter="counter"
                        :totalPages="totalPages"
                        :totalItems="totalRows"
                        :size="size"
                      />
                      <div v-if="totalRows > 10" class="mt-3 flex justify-center items-center">
                        <Pagination :currentPage="pageForPagination" :totalCount="totalRows" :pageSize="size" @onPageChange="onPageChange" />
                      </div>
                    </div>
                    <div v-else class="w-full h-60 border border-neutral-250 rounded-lg text-sm bg-white flex justify-center items-center"><p>No student available</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="selectedTab === 'Grade'">
        <div class="shadow-small rounded-lg bg-white p-6">
          <div v-if="!isLevel1Available" class="flex justify-center items-center">
            <template v-if="isFetching">
              <vue-simple-spinner size="large" class="py-32"></vue-simple-spinner>
            </template>
            <div class="py-20" v-else>
              <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto" />
              <div class="font-bold text-2xl py-5">{{ isScheduleEmpty ? 'This class schedule doesn’t have activity' : 'This schedule doesn’t have grading system' }}</div>
              <div class="flex justify-center items-center gap-8">
                <Button buttonText="Create New" type="primary" :disabled="isScheduleEmpty" @action="showCreateNewGradingModal()" />
                <Button buttonText="Copy from Other Schedule" type="secondary" @action="showCopyFromScheduleModal()" />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="tab flex mb-3">
              <div
                v-for="(tabGrading, tabIndex) in tabLevel"
                :key="tabIndex"
                @click="selectGradingTab(tabGrading)"
                class="tab__item text-sm px-5 pb-2 rounded-tl-lg cursor-pointer font-semibold"
                :class="[tabGrading === levelTabSelected ? 'text-yellow border-yellow border-b-2' : 'text-neutral-400 border-neutral-400 border-b']"
              >
                {{ `Level ${tabGrading}` }}
              </div>
              <div
                @click="selectGradingTab('Summary')"
                class="text-sm px-5 pb-2 rounded-tl-lg font-semibold"
                :class="[
                  'Summary' === levelTabSelected ? 'text-yellow border-yellow border-b-2 ' : 'text-neutral-400 border-neutral-400 border-b ',
                  isSummaryTabEnable ? 'cursor-pointer' : 'cursor-not-allowed'
                ]"
              >
                Summary
              </div>
            </div>
            <template>
              <div class="">
                <template v-if="isFetching">
                  <vue-simple-spinner size="large" class="py-32"></vue-simple-spinner>
                </template>
                <template v-else>
                  <div class="flex justify-between items-center mb-3">
                    <div class="font-semibold text-neutral-500">{{ Number.isInteger(this.levelTabSelected) ? `Level ${this.levelTabSelected}` : this.levelTabSelected }}</div>
                    <div class="flex items-center gap-2">
                      <Button buttonText="Add Item" type="primary" @action="showEditComponentModal(null, 'New')" v-if="levelTabSelected !== 'Summary'" />
                      <Button buttonText="Add Level" type="secondary" @action="addLevel()" v-if="this.isAddLevelButtonEnable" />
                      <Button buttonText="Download" type="tertiary" @action="donwloadGradingSummary()" v-if="this.levelTabSelected === 'Summary'" />
                    </div>
                  </div>
                  <Table
                    :columns="levelTabSelected === 'Summary' ? columnsSummary : columnsGrading"
                    :list="grades"
                    :drag="levelTabSelected === 'Summary' ? false : true"
                    @handleChange="handleChangeTable"
                    @handleMutateTable="handleMutateList"
                  >
                    <template v-slot="{ entry, column }" v-if="levelTabSelected === 'Summary'">
                      <div v-if="column.field === 'student_name'">
                        {{ entry[column.field] || 'undefined' }}
                      </div>
                      <div class="text-center" v-else>{{ entry[column.field] || 0 }}</div>
                    </template>
                    <template v-slot="{ entry, column }" v-else>
                      <div v-if="column.field === 'btn'" class="flex items-center gap-2 w-1/10">
                        <Button
                          class="mr-2"
                          type="primary"
                          size="icon"
                          :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                          @action="showEditComponentModal(entry, 'Edit')"
                        />
                        <Button type="delete" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="showDeleteConfirmationModal(entry)" />
                      </div>
                      <div v-else-if="column.field === 'scoringSchemaGroupItems'">
                        <div class="pl-12" v-if="entry.scoringSchemaGroupItems.length === 0">-</div>
                        <div v-else>
                          <div v-for="item in entry.scoringSchemaGroupItems" :key="item.id">{{ percentageAverage(item, item.percentage) }}% {{ item.title }}</div>
                        </div>
                      </div>
                      <div v-else class="">{{ entry[column.field] || '-' }}</div>
                    </template>
                  </Table>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
      <Modal :modalVisible="isEditComponentModal" @close="hideEditComponentModal()" width="640px" id="editComponent">
        <template slot="modal-content">
          <div class="text-left my-6">
            <p class="text-2xl font-bold mb-3">{{ itemMode }} Component</p>
            <div class="mb-6">
              <TextField borderEnabled label="Title" type="text" v-model="editComponentSelected.title" />
            </div>
            <div class="mb-6" v-if="itemMode === 'New'">
              <TextField borderEnabled label="Topic" type="text" v-model="editComponentSelected.topic" />
            </div>
            <div class="mb-3" v-for="(item, itemIdx) in editComponentSelected.scoringSchemaGroupItems" :key="itemIdx + 'editComponent'" v-show="itemMode === 'Edit'">
              <div class="flex gap-2">
                <div class="w-1/2">
                  <div v-if="item.referenceType === 'Free'">
                    <TextField borderEnabled label="Title" type="text" v-model="item.title" :default="item.title ? item.title : null" placeholder="Enter title..." />
                  </div>
                  <div v-if="item.referenceType === `Level ${levelTabSelected - 1}`">
                    <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Title</label>
                    <Dropdown :options="referenceGradingList" maxHeight="100px" v-model="item.title" :default="item.title ? item.title : null" placeholder="Search title by name..." />
                  </div>
                </div>
                <div class="w-1/5" v-if="!editComponentSelected.isAverage">
                  <TextField borderEnabled label="Percentage" type="number" :indexData="itemIdx" @input="handleLimitPercentage" v-model="item.percentage" />
                </div>
                <div class="w-3/10">
                  <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Reference</label>
                  <Dropdown
                    :disabled="levelTabSelected === 1"
                    :options="referenceTypeOptions"
                    v-model="item.referenceType"
                    :indexData="itemIdx"
                    @onChangeSelected="handleSwitchingReferenceType"
                    :default="item.referenceType ? item.referenceType : referenceTypeSelected"
                  />
                </div>
                <div class="my-auto pt-5">
                  <Button type="secondary" size="icon" :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')" @action="deleteComponentItems(item, itemIdx)" />
                </div>
              </div>
            </div>
            <div v-if="editComponentSelected.scoringSchemaGroupItems && itemMode === 'Edit'">
              <div class="text-red mb-3 text-xs">*total percentage must be 100</div>
              <input type="checkbox" id="average" v-model="editComponentSelected.isAverage" class="styled-checkbox" />
              <label class="text-xs pl-2" for="average">Average Percentage</label>
            </div>

            <div class="flex justify-start my-5" v-if="itemMode === 'Edit'">
              <Button buttonText="+ Add" type="tertiary" @action="addComponentItems()" />
            </div>
            <div class="flex justify-end my-5">
              <Button buttonText="Save Component" :disabled="!isSaveItemComponentEnable" @action="saveGradingComponent()" />
            </div>
          </div>
        </template>
      </Modal>
      <Modal :modalVisible="isDeleteConfirmationModal" @close="hideDeleteConfirmationModal()" id="createNewGrading">
        <template slot="modal-content">
          <div class="text-center my-6">
            <p class="text-2xl font-bold mb-3">Are you sure to delete?</p>
            <div class="flex justify-center gap-4 my-5">
              <Button buttonText="Delete" type="delete" @action="deleteGradingData()" />
              <Button buttonText="Cancel" type="tertiary" @action="hideDeleteConfirmationModal()" />
            </div>
          </div>
        </template>
      </Modal>
      <Modal :modalVisible="isCreateNewGradingModal" @close="hideCreateNewGradingModal()" width="640px" id="createNewGrading">
        <template slot="modal-content">
          <div class="text-left my-6">
            <p class="text-2xl font-bold mb-3">Mau ambil dari mana?</p>
            <div class="">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3 block">Status</label>
              <Dropdown :options="createNewGradingOptions" v-model="selectedNewGrading" :default="selectedNewGrading" />
            </div>
            <div class="flex justify-end my-5">
              <Button buttonText="Save Item" @action="createNewGrading()" />
            </div>
          </div>
        </template>
      </Modal>
      <Modal :modalVisible="isCopyFromScheduleModal" @close="hideCopyFromScheduleModal()" width="640px" id="copyFromSchedule">
        <template slot="modal-content">
          <div class="my-6 text-left relative">
            <p class="text-2xl font-bold mb-3">Select From Other Schedule</p>
            <div class="relative w-full mb-5">
              <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Schedule Name</label>
              <div class="relative">
                <TextField type="text" :enterKeyAction="searchingSchedule" v-model="copyFromScheduleQuery" additionalClass="pr-10" borderEnabled placeholder="Search schedule by name..." />
                <div class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" v-if="copyFromScheduleQuery !== '' && isSearchingSchedule" @click="clearKeyWord">
                  <Close color="#58595B" />
                </div>
                <div class="absolute right-3 top-1/2 transform -translate-y-1/2" v-else>
                  <Search color="#58595B" />
                </div>
              </div>
              <div class="absolute left-0 py-2 max-h-40 overflow-y-auto rounded-lg w-full bg-neutral-500 bg-opacity-90 z-10" v-if="isSearchingSchedule">
                <template v-if="scheduleClassList.length > 0 && !isFetching">
                  <div ref="scheduleClassList" class="">
                    <div
                      @click="selectSchedule(sch)"
                      class="py-1 px-3 text-white cursor-pointer truncate hover:bg-yellow-primary hover:text-white font-normal last:pb-0"
                      v-for="sch in scheduleClassList"
                      :key="sch.id"
                    >
                      {{ sch.nama }}
                    </div>
                  </div>
                </template>
                <template v-if="scheduleClassList.length === 0 && !isFetching">
                  <p class="py-1 px-3 text-white font-normal text-center">Schedule is not found.</p>
                </template>
                <template v-if="isFetching">
                  <vue-simple-spinner size="large" class="py-2"></vue-simple-spinner>
                </template>
              </div>
            </div>
            <div class="flex justify-end mb-5">
              <Button buttonText="Copy Schedule" :disabled="!selectedScheduleId" @action="generateGradingData('COPY')" />
            </div>
          </div>
        </template>
      </Modal>

      <Modal :modalVisible="isAddStudentModal" @close="hideAddStudentModal()" width="640px" id="addStudent">
        <template slot="modal-content">
          <div class="text-center my-6">
            <p class="text-2xl font-bold mb-3">Are you sure to add student to this class?</p>
            <p class="text-sm mb-4">
              You are about to permanently add new student into the Student List. You will not be able to remove student from the student list one you’ve proceed. This action cannot be undone.
            </p>
            <div class="flex justify-center items-center gap-8">
              <Button buttonText="Cancel" type="tertiary" @action="hideAddStudentModal()" />
              <Button buttonText="Proceed" type="secondary" @action="enrollStudent()" />
            </div>
          </div>
        </template>
      </Modal>
      <Modal :modalVisible="alreadyExistModal" @close="hideAlreadyExistModal()" width="640px" id="alreadyExistModal">
        <template slot="modal-content">
          <div class="text-center my-6">
            <p class="text-2xl font-bold mb-3">Failed to Enroll Student</p>
            <p class="text-sm mb-4">Because the students already enroll in this class schedule.</p>
            <div class="overflow-y-auto h-36 border rounded-lg p-2 text-left mb-3">
              <p class="text-sm">{{ failedStudent.join(', ') }}</p>
            </div>
            <div class="flex justify-center items-center gap-8">
              <Button buttonText="Ok" type="secondary" @action="hideAlreadyExistModal()" />
            </div>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
var moment = require('moment')
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { showVueToast, isNotEmptyErrorForm } from '@/utils'
import { mapActions } from 'vuex'

const CONFIG = {
  customerServiceNumber: 'Phone Number'
}

export default {
  name: 'JadwalKelasForm',
  mounted: function () {
    this.modeForm = this.$route.meta.mode
    this.selectedSource = this.source[0]
    this.selectedPipelineStage = this.allObject
    if (this.modeForm === 'Edit' || this.modeForm === 'Copy') {
      this.idForm = this.$route.params.id
      this.getProgramList()
    }
    this.initData()
  },
  data: function () {
    return {
      isDisabled: false,
      isFormRegisterVisible: false,
      isAddStudentActive: false,
      referenceGradingList: [],
      referenceGradingListComplete: [],
      tabLevel: [],
      levelTabSelected: 1,
      scheduleName: '',
      scheduleClassList: [],
      scheduleGradingList: [],
      isCopyFromScheduleModal: false,
      isCreateNewGradingModal: false,
      isDeleteConfirmationModal: false,
      createNewGradingOptions: ['Jadwal', 'Free'],
      referenceTypeOptions: ['Level 1', 'Free'],
      referenceTypeSelected: 'Free',
      activityItemsOfGrading: [],
      selectedNewGrading: 'Jadwal',
      selectedScheduleId: '',
      copyFromScheduleQuery: '',
      isSearchingSchedule: false,
      isSearchingGradingList: false,
      isEditComponentModal: false,
      editComponentSelected: {
        title: '',
        topic: '',
        scoringSchemaGroupItems: []
      },
      isFetching: false,
      isFetchingComponent: false,
      columnsGrading: [
        { field: 'title', label: 'Title' },
        { field: 'topic', label: 'Topic' },
        { field: 'scoringSchemaGroupItems', label: 'Component' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ],
      columnsSummary: [],
      grades: [],
      latestGrades: [],
      breadcrumb: [],
      totalLevel: 0,
      isLevel1Available: false,
      scoringSchemaGroupId: null,
      itemMode: '',
      isScoringSchemaGroupItemsEmpty: true,
      const: [],
      isDeleteComponentItems: false,
      //////GRADING///////
      selectedInstructor: null,
      selectedStatus: null,
      selectedPretest: null,
      selectedExam: null,
      selectedTab: 'Detail',
      source: [
        {
          name: 'CRM',
          value: 'CRM'
        },
        {
          name: 'Manual',
          value: 'MANUAL'
        }
      ],
      programs: [],
      pipelineStage: [],
      prospectList: [],
      studentList: [],
      isReset: false,
      isAddStudentModal: false,
      selectedWorkflow: null,
      selectedSource: null,
      selectedProgramStudent: null,
      selectedPipelineStage: null,
      allObject: {
        name: 'All',
        value: ''
      },
      selectedProspect: [],
      failedStudent: [],
      userFullName: '',
      userPhoneNumber: '',
      userEmail: '',
      keyword: '',
      counter: 0,
      counterStudent: 0,
      currentPage: 0,
      currentPageStudent: 0,
      size: 10,
      sizeStudent: 20,
      totalPages: 0,
      totalPagesStudent: 0,
      totalRows: 0,
      totalRowsStudent: 0,
      alreadyExistModal: false,
      statusOption: [
        {
          name: 'Close',
          value: 0
        },
        {
          name: 'Open',
          value: 1
        }
      ],
      isFetching: false,
      isSearching: false,
      classKeyword: '',
      debounce: null,
      options: {
        id: 0,
        kelas_id: 0,
        nama: '',
        batch: 1,
        link_wa: '',
        customerServiceNumber: '',
        link_zoom: 'Link Zoom (menyusul di Hari H pelaksanaan kelas)',
        schedule: [],
        document: [],
        active: 0,
        jumlah_peserta_capture: '',
        jumlah_peserta_manual: 0
      },
      phoneInputType: 'number',
      listExamUrl: [],
      listPretestUrl: [],
      showProgress: true,
      counterProgress: 0,
      idForm: 0,
      modeForm: null,
      schedule: { id: 0, start_date: '', end_date: '' },
      list_schedules: [],
      document: { id: 0, name: '', file_url: '' },
      list_document: [],
      columns: [
        { field: 'id', label: 'Id' },
        { field: 'kelas_id', label: 'Class' },
        { field: 'nama', label: 'Schedule Name' },
        { field: 'instructur_id', label: 'Instructor' },
        { field: 'batch', label: 'Batch' },
        { field: 'jumlah_peserta_manual', label: 'Number of Participants' },
        { field: 'customerServiceNumber', label: 'Customer Service Number' },
        { field: 'link_wa', label: 'Whatsapp Link' },
        { field: 'link_zoom', label: 'Zoom Link' },
        { field: 'host_key', label: 'Host Key' },
        { field: 'active', label: 'Active' },
        { field: 'pretest_exercise_id', label: 'Pretest' },
        { field: 'exam_exercise_id', label: 'Exam' },
        // { field: 'exam_url', label: 'Exam Url' },
        // { field: 'pretest_url', label: 'Pretest Url' },
        // { field: 'jumlah_peserta_capture', label: 'Screen Shoot by Admin' },
        { field: 'schedule', label: 'Daftar Pertemuan' },
        { field: 'document', label: 'Daftar Document' }
        // { field: 'java_instructur_id', label: 'Java Instructur Id' },
      ],
      masters: {},
      customer: {},
      list_exercise_pretest: [],
      list_exercise_exam: [],
      list_java_instructur_id: [],
      levelId: 0,
      locked: false,
      stillLoading: true,
      showProfileLogo: true,
      profileLogo: '',
      list_kelas: [],
      list_instructur: [],
      dropzoneLogoOptions: {
        url: window.axios.defaults.baseURL + 'api/file/upload',
        maxFilesize: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        headers: {
          Authorization: 'jwt ' + this.$store.getters['customer/customer'].token
        }
      }
    }
  },
  methods: {
    ...mapActions('kelas', ['GET_LIST_PROGRAM']),
    ...mapActions('absensireport', ['GET_LIST_ABSENSIREPORT']),
    ...mapActions('student', ['GET_PROSPECT', 'GET_PIPELINE_STAGE', 'ENROLL_PROSPECT']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('jadwalkelas', [
      'GET_GRADING_DATA_ALL',
      'GET_GRADING_DATA_GROUP',
      'CREATE_SINGLE_GRADING',
      'GET_GRADING_DATA',
      'GET_JADWALKELAS',
      'CREATE_MULTIPLE_GRADING',
      'DELETE_GRADING_DATA',
      'UPDATE_GRADING_DATA',
      'DELETE_GRADING_COMPONENT',
      'GET_GRADING_DATA_SUMMARY',
      'UPDATE_MULTIPLE_GRADING',
      'DOWNLOAD_GRADING_SUMMARY'
    ]),
    toLMSClassListMenu() {
      this.$router.push('/jadwalkelas')
    },
    ///////GRADING SECTION//////
    handleChangeTable(event) {
      this.showLoading()
      const items = this.grades
        .map((grade, index) => {
          return {
            ...grade,
            seqNo: index + 1,
            items: grade.scoringSchemaGroupItems
          }
        })
        .map((grade2) => {
          delete grade2.scoringSchemaGroupItems
          return {
            ...grade2
          }
        })

      const payload = {
        scoringSchemaGroup: {
          items
        }
      }

      this.UPDATE_MULTIPLE_GRADING({
        lmsClassId: this.jadwalKelasId,
        level: this.levelTabSelected,
        payload
      })
        .then((res) => {
          this.hideLoading()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    handleMutateList(value) {
      this.grades = value
    },
    handleLimitPercentage(value, index) {
      if (value >= 100) {
        this.editComponentSelected.scoringSchemaGroupItems[index].percentage = 100
      }
      if (value.length > 1 && value.charAt(0) === '0') {
        this.editComponentSelected.scoringSchemaGroupItems[index].percentage = ''
      }
    },
    handleSwitchingReferenceType(value, index) {
      this.editComponentSelected.scoringSchemaGroupItems[index].title = ''
    },
    showDeleteConfirmationModal(data) {
      this.scoringSchemaGroupId = data.id
      this.isDeleteConfirmationModal = true
    },
    hideDeleteConfirmationModal() {
      this.isDeleteConfirmationModal = false
    },
    showEditComponentModal(grade, mode) {
      this.itemMode = mode
      if (grade) {
        this.scoringSchemaGroupId = grade.id
        grade.scoringSchemaGroupItems.length === 0 ? (this.isScoringSchemaGroupItemsEmpty = true) : (this.isScoringSchemaGroupItemsEmpty = false)
        this.editComponentSelected = { ...grade, isAverage: grade.calculatedType === 'AVERAGE' ? true : false, index: this.grades.indexOf(grade) }
      } else {
        this.editComponentSelected = {
          title: '',
          topic: '',
          scoringSchemaGroupItems: []
        }
      }
      this.isEditComponentModal = true
    },
    hideEditComponentModal() {
      if (this.itemMode === 'Edit') {
        if (this.isScoringSchemaGroupItemsEmpty) {
          this.grades[this.editComponentSelected.index].scoringSchemaGroupItems = []
        } else {
          if (this.isDeleteComponentItems) {
            this.saveGradingComponent()
          } else {
            this.getGradingData()
          }
        }
      }
      this.isScoringSchemaGroupItemsEmpty = true
      this.isEditComponentModal = false
    },
    showCopyFromScheduleModal() {
      this.isCopyFromScheduleModal = true
    },
    hideCopyFromScheduleModal() {
      this.isCopyFromScheduleModal = false
      this.copyFromScheduleQuery = ''
      this.selectedScheduleId = ''
      this.isSearchingSchedule = false
    },
    showCreateNewGradingModal() {
      this.isCreateNewGradingModal = true
    },
    hideCreateNewGradingModal() {
      this.isCreateNewGradingModal = false
      this.selectedNewGrading = 'Jadwal'
    },
    addLevel(isLoadData) {
      this.tabLevel.push(this.tabLevel.length + 1)
      if (!isLoadData) {
        this.totalLevel = this.totalLevel + 1
      }
    },
    checkReferenceType(value) {
      let result = ''
      switch (value) {
        case 'Free':
          result = 'ScoringSchemaGroupItem'
          break
        case 'ScoringSchemaGroupItem':
          result = 'Free'
          break
        case 'SCORING_GROUP_ID':
          result = `Level ${this.levelTabSelected - 1}`
          break
        default:
          result = 'SCORING_GROUP_ID'
          break
      }
      return result
    },

    percentageAverage(item, percentage) {
      const calculatedType = item.isAverage ? 'AVERAGE' : 'PERCENTAGE'
      if (calculatedType === 'AVERAGE') {
        const calculate = (1 / item.scoringSchemaGroupItems.length) * 100
        const finalResult = Number.isInteger(calculate) ? calculate : parseFloat(calculate.toFixed(2))
        return finalResult
      }
      return percentage
    },

    getTotalLevel(loadLevel) {
      this.GET_GRADING_DATA_GROUP({
        lmsClassId: this.jadwalKelasId
      })
        .then((res) => {
          const data = res.data.data
          this.totalLevel = data.count.totalLevel
          if (loadLevel) {
            for (let i = 0; i < data.count.totalLevel; i++) {
              this.addLevel(true)
            }
          }
        })
        .catch((err) => {})
    },
    checkChildId(id, title) {
      if (id) return id
      return this.referenceGradingListComplete.filter((item) => item.title === title)[0].id
    },

    saveGradingComponent() {
      if (this.itemMode === 'New') {
        this.showLoading()
        const payload = {
          topic: this.editComponentSelected.topic,
          title: this.editComponentSelected.title,
          calculatedType: 'PERCENTAGE',
          level: this.levelTabSelected,
          seqNo: this.grades.length + 1
        }

        this.CREATE_SINGLE_GRADING({
          lmsClassId: this.jadwalKelasId,
          payload
        })
          .then((res) => {
            this.isDeleteComponentItems = false
            this.hideEditComponentModal()
            this.getGradingData()
          })
          .catch((err) => {
            this.hideLoading()
          })
        return
      }
      const items = []
      this.editComponentSelected.scoringSchemaGroupItems.map((item, index) => {
        const referenceType = this.checkReferenceType(item.referenceType)
        const percentage = this.percentageAverage(this.editComponentSelected, item.percentage)
        if (item.referenceType === 'Free') {
          items.push({
            ...item,
            referenceType,
            percentage,
            seqNo: index + 1
          })
        } else {
          items.push({
            ...item,
            id: this.checkChildId(item.id, item.title),
            referenceType,
            percentage,
            seqNo: index + 1
          })
        }
      })

      const payload = {
        level: this.levelTabSelected,
        title: this.editComponentSelected.title,
        topic: this.editComponentSelected.topic,
        calculatedType: this.editComponentSelected.isAverage ? 'AVERAGE' : 'PERCENTAGE',
        seqNo: this.editComponentSelected.seqNo,
        items
      }

      this.UPDATE_GRADING_DATA({
        scoringSchemaGroupId: this.editComponentSelected.id,
        payload
      })
        .then((res) => {
          this.isEditComponentModal = false
          this.getGradingData()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    selectGradingTab(tab) {
      if (tab === 'Summary' && !this.isSummaryTabEnable) return
      if (tab === this.levelTabSelected) return
      this.levelTabSelected = tab
      if (!Number.isInteger(this.levelTabSelected)) {
        this.getGradingSummary()
        return
      }
      this.getGradingData()
    },
    donwloadGradingSummary() {
      this.showLoading()
      this.DOWNLOAD_GRADING_SUMMARY({
        lmsClassId: this.jadwalKelasId
      }).then((res) => {
        const url = URL.createObjectURL(res.data)
        const a = document.createElement('a')
        a.download = 'grading_summary.xlsx'
        a.href = url
        a.target = '_self'

        a.click()

        setTimeout(function () {
          a.remove()
          URL.revokeObjectURL(url)
        }, 100)
        this.hideLoading()
      })
    },
    getGradingSummary() {
      this.isFetching = true
      this.columnsSummary = []
      this.grades = []
      this.columnsSummary.push({ field: 'student_name', label: 'Student Name' })
      this.GET_GRADING_DATA_SUMMARY({
        lmsClassId: this.jadwalKelasId,
        params: {
          page: 0,
          size: 100
        }
      }).then((res) => {
        this.isFetching = false
        this.hideLoading()
        const summary = res.data.data
        if (summary.length !== 0) {
          summary[0].component.map((score) => {
            const item = { field: score.title, label: score.title, position: 'center' }
            this.columnsSummary.push(item)
          })
          this.columnsSummary.push({ field: 'total_student_score', label: 'Final Score', position: 'center' })

          summary.map((item) => {
            const result = {
              student_name: item.student_name,
              total_student_score: item.total_student_score ? (Number.isInteger(item.total_student_score) ? item.total_student_score : item.total_student_score.toFixed(2)) : 0
            }
            item.component.map((comp) => {
              result[`${comp.title}`] = comp.student_score ? (Number.isInteger(comp.student_score) ? comp.student_score : comp.student_score.toFixed(2) || 0) : 0
            })
            this.grades.push(result)
          })
        }
      })
    },
    filledColumn(data) {
      const result = []
    },
    getAllGrading() {
      this.GET_GRADING_DATA_ALL({
        lmsClassId: this.jadwalKelasId
      }).then((res) => {
        setTimeout(() => {
          this.referenceGradingListComplete = res.data.data.filter((item) => item.level === this.levelTabSelected - 1)
          this.latestGrades = res.data.data.filter((item) => item.level === this.totalLevel)
          this.referenceGradingList = res.data.data.filter((item) => item.level === this.levelTabSelected - 1 && item.scoringSchemaGroupItems.length !== 0).map((item) => item.title)
        }, 200)
      })
    },
    getGradingData() {
      this.grades = []
      this.isFetching = true
      this.getAllGrading()
      this.GET_GRADING_DATA({
        lmsClassId: this.jadwalKelasId,
        level: this.levelTabSelected
      })
        .then((res) => {
          const data = res.data.data
          this.grades = data.map((item) => {
            const scoringSchemaGroupItems = item.scoringSchemaGroupItems.map((score) => {
              return {
                ...score,
                referenceType: this.checkReferenceType(score.referenceType)
              }
            })
            return {
              ...item,
              scoringSchemaGroupItems
            }
          })
          const checkUniqueItem = this.referenceGradingList.filter((item) => item.level === this.levelTabSelected)
          if (checkUniqueItem.length === 0) {
            this.referenceGradingList.push({ data, level: this.levelTabSelected })
          }
          if (this.levelTabSelected === 1 && data.length !== 0) this.isLevel1Available = true
          this.isFetching = false
          this.hideLoading()
        })
        .catch((err) => {
          this.isFetching = false
          this.hideLoading()
        })
    },
    createNewGrading() {
      if (this.selectedNewGrading === 'Free') {
        this.hideCreateNewGradingModal()
        this.isLevel1Available = true
        return
      }
      this.generateGradingData('NEW')
    },
    generateGradingData(mode) {
      let masterId = mode === 'COPY' ? this.selectedScheduleId : this.jadwalKelasId
      this.showLoading()
      this.GET_JADWALKELAS({
        masterId,
        customerId: this.clientId
      })
        .then((res) => {
          const schedule = res.data.data.schedule
          if (schedule.length === 0) {
            this.hideLoading()
            showVueToast('This class schedule doesn’t have activity!', 'error', 2000)
          }

          const scoringSchemaGroup = schedule.map((sch, idx) => {
            return {
              calculatedType: 'PERCENTAGE',
              level: this.levelTabSelected,
              seqNo: idx + 1,
              title: `Pertemuan ${idx + 1}`,
              topic: sch.description
            }
          })
          this.CREATE_MULTIPLE_GRADING({
            lmsClassId: this.jadwalKelasId,
            payload: {
              scoringSchemaGroup
            }
          })
            .then((res) => {
              this.hideCopyFromScheduleModal()
              this.hideCreateNewGradingModal()
              this.getGradingData()
            })
            .catch((err) => {
              this.hideLoading()
            })
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    deleteGradingData() {
      this.showLoading()
      this.DELETE_GRADING_DATA({
        scoringSchemaGroupId: this.scoringSchemaGroupId
      })
        .then((res) => {
          this.hideLoading()
          this.hideDeleteConfirmationModal()
          this.getGradingData()
        })
        .catch((err) => {
          this.hideLoading()
        })
    },
    clearKeyWord() {
      this.copyFromScheduleQuery = ''
      this.scheduleClassList = []
      this.isSearchingSchedule = false
    },
    searchingSchedule() {
      this.isSearchingSchedule = true
      this.isFetching = true
      const paramsTemp = queryString.stringify({
        ...{
          limit: 10,
          sort: 'name',
          nama: this.copyFromScheduleQuery
        }
      })
      this.$store
        .dispatch('jadwalkelas/GET_LIST_JADWALKELAS', {
          masterType: 'jadwalkelas',
          customerId: this.clientId,
          params: paramsTemp
        })
        .then((res) => {
          this.scheduleClassList = res.data.data.filter((sch) => sch.deliveryType === 'WEBINAR')
          this.isFetching = false
        })
        .catch(() => {
          showVueToast('Fetching data is failed!', 'error', 2000)
        })
    },
    isEven(value) {
      if (value % 2 === 0) return true
      return false
    },
    addComponentItems() {
      const item = {
        title: '',
        percentage: '',
        referenceType: this.levelTabSelected === 1 ? 'Free' : `Level ${this.levelTabSelected - 1}`,
        seqNo: this.editComponentSelected.scoringSchemaGroupItems.length + 1
      }
      this.editComponentSelected.scoringSchemaGroupItems.push(item)
    },
    deleteComponentItems(item, index) {
      if (item.id) {
        this.DELETE_GRADING_COMPONENT({
          scoringSchemaGroupItemId: item.id
        })
          .then((res) => {
            this.isDeleteComponentItems = true
          })
          .catch((err) => {
            showVueToast('Failed to delete', 'error', 2000)
          })
      }
      this.editComponentSelected.scoringSchemaGroupItems.splice(index, 1)
    },
    selectSchedule(data) {
      this.copyFromScheduleQuery = data.nama
      this.selectedScheduleId = data.id
      this.isSearchingSchedule = false
    },
    ///////GRADING SECTION//////
    selectTab(tab) {
      this.selectedTab = tab
      if (tab === 'Grade') {
        this.levelTabSelected = 1
        this.getGradingData()
      }
    },
    resetFilterStudent() {
      this.isFormRegisterVisible = false
      this.userFullName = this.userEmail = this.userPhoneNumber = ''
      this.errors['userFullName'] = this.errors['userEmail'] = this.errors['userPhoneNumber'] = undefined
      this.selectedSource = this.source[0]
      this.selectedProgramStudent = this.programs[0]
      this.selectedPipelineStage = this.allObject
      this.currentPage = 0
      this.getProspectList()
    },
    searchProspect() {
      this.currentPage = 0
      this.getProspectList()
    },
    addNewStudent() {
      if (!this.errors['userFullName'] && !this.errors['userEmail'] && !this.errors['userPhoneNumber']) {
        if (this.userFullName && this.userEmail && this.userPhoneNumber) {
          this.selectedProspect = []
          this.selectedProspect.push({
            id: null,
            source: 'CRM',
            name: this.userFullName,
            email: this.userEmail,
            phoneNumber: this.userPhoneNumber
          })
          this.enrollStudent()
        } else {
          this.errors['userFullName'] = 'Name is required'
          this.errors['userEmail'] = 'Email is required'
          this.errors['userPhoneNumber'] = 'Phone number is required'
          this.$forceUpdate()
        }
      }
    },
    getProspectList() {
      if (this.selectedSource.value === 'MANUAL') {
        this.userFullName = this.userEmail = this.userPhoneNumber = ''
        this.errors['userFullName'] = this.errors['userEmail'] = this.errors['userPhoneNumber'] = undefined
        this.isFormRegisterVisible = true
      } else {
        this.isFormRegisterVisible = false
        this.$forceUpdate()
        this.GET_PROSPECT({
          params: {
            page: this.currentPage,
            size: this.size,
            source: this.selectedSource.value,
            programId: this.selectedProgramStudent.value,
            coreClassId: '',
            pipelineStageId: this.selectedPipelineStage.value,
            query: this.keyword
          }
        }).then((res) => {
          this.counter === 3 ? (this.counter = 0) : this.counter++
          this.totalPages = res.data.data.totalPages
          this.totalRows = res.data.data.totalRows
          this.size = res.data.data.size
          this.currentPage = res.data.data.currentPage
          this.prospectList = []
          res.data.data.data.forEach((prospect) => {
            this.prospectList.push({
              valueParams: { id: prospect.id, source: prospect.source, name: prospect.fullName, email: prospect.email, phoneNumber: prospect.phoneNumber },
              firstQuery: { id: prospect.id, source: prospect.source, name: prospect.fullName, email: prospect.email, phoneNumber: prospect.phoneNumber },
              firstColumn: prospect.fullName,
              firstColumnDesc: prospect.email,
              secondColumn: prospect.phoneNumber
            })
          })
        })
      }
    },
    getStudentList() {
      this.showLoading()
      this.GET_LIST_ABSENSIREPORT({
        customerId: this.clientId,
        params: queryString.stringify({
          page: this.currentPageStudent + 1,
          limit: this.sizeStudent,
          jadwal_kelas_id: this.$route.params.id
        })
      })
        .then((res) => {
          this.counterStudent === 3 ? (this.counterStudent = 0) : this.counterStudent++
          res.data.data.length === 0 ? (this.totalPagesStudent = 1) : Math.ceil(res.data.data.length / this.sizeStudent)
          this.totalRowsStudent = res.data.pagination.total
          this.studentList = []
          res.data.data.forEach((student) => {
            this.studentList.push({
              valueParams: { id: student.id, name: student.nama, email: student.email, phoneNumber: student.hp },
              firstQuery: { id: student.id, name: student.nama, email: student.email, phoneNumber: student.hp },
              firstColumn: student.nama,
              firstColumnDesc: student.email,
              secondColumn: student.hp
            })
          })
        })
        .catch(() => showVueToast('Request failed, please try again later!', 'error', 2000))
      setTimeout(() => this.hideLoading(), 2000)
    },
    getProgramList() {
      this.programs = []
      this.GET_LIST_PROGRAM({}).then((res) => {
        this.selectedProgramStudent = {
          name: res.data.data[0].name,
          value: res.data.data[0].id
        }
        for (let i = 0; i < res.data.data.length; i++) {
          this.programs.push({
            name: res.data.data[i].name,
            value: res.data.data[i].id
          })
        }
        this.getProspectList()
        this.getStudentList()
      })
    },
    getPipelineStage() {
      this.pipelineStage = []
      this.pipelineStage.push(this.allObject)
      this.GET_PIPELINE_STAGE({ params: { programId: this.selectedProgramStudent.value, customerId: this.clientId } }).then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.pipelineStage.push({
            name: res.data.data[i].name,
            value: res.data.data[i].id
          })
        }
      })
    },
    onPageChange(page) {
      this.currentPage = page - 1
      this.getProspectList()
    },
    onPageChangeStudent(page) {
      this.currentPageStudent = page - 1
      this.getStudentList()
    },
    resetSelectedStudent() {
      this.isReset = true
      setTimeout(async () => {
        this.isReset = await false
        this.selectedProspect = []
      }, 500)
    },
    addStudent(prospect) {
      this.isReset = true
      setTimeout(async () => {
        this.isReset = await false
        this.selectedProspect = []
        this.selectedProspect.push(prospect)
      }, 500)
      this.isAddStudentModal = true
    },
    enrollStudent() {
      this.showLoading()
      this.failedStudent = []
      this.ENROLL_PROSPECT({
        lmsClassId: this.$route.params.id,
        params: { customerId: this.clientId },
        payload: { students: this.selectedProspect }
      })
        .then(async (res) => {
          await res.data.data.forEach((element) => {
            if (!element.success) {
              this.failedStudent.push(element.message.split(' ')[1])
            }
          })
          this.resetFilterStudent()
          this.resetSelectedStudent()
          this.hideAddStudentModal()
          if (this.failedStudent.length > 0) {
            this.showAlreadyExistModal()
          } else {
            showVueToast('Enroll student successfully!', 'success', 2000)
            this.getStudentList()
          }
        })
        .catch(() => showVueToast('Request failed, please try again later!', 'error', 2000))
      setTimeout(() => this.hideLoading(), 2000)
    },
    actionCheckbox(val) {
      this.selectedProspect = val
    },
    showAddStudentModal() {
      this.isAddStudentModal = true
    },
    hideAddStudentModal() {
      this.isAddStudentModal = false
    },
    showAlreadyExistModal() {
      this.alreadyExistModal = true
    },
    hideAlreadyExistModal() {
      this.alreadyExistModal = false
      this.getStudentList()
    },
    initData() {
      if (this.$route.meta.name === 'customer' && this.$route.meta.mode === 'Edit') {
        if (this.$route.params.id != this.$store.getters['customer/customer'].id) {
          this.locked = true
        }
      }
      if (this.locked) {
        return null
      }
      this.getListInstructur()
      this.counterProgress = 0
      const forCustomerStringify = JSON.stringify(this.$store.getters['customer/customer'])
      this.customer = JSON.parse(forCustomerStringify)
      this.getListExercise()
      if (this.modeForm === 'Edit' || this.modeForm === 'Copy') {
        this.getTotalLevel(true)
        this.$store
          .dispatch('jadwalkelas/GET_JADWALKELAS', {
            masterType: this.$route.meta.name,
            customerId: this.clientId,
            masterId: this.idForm
          })
          .then((resp) => {
            if (resp.data.code === 200) {
              const forOptionsStringify = JSON.stringify(this.$store.getters['jadwalkelas/jadwalkelas'])
              this.options = JSON.parse(forOptionsStringify)
              if ('schedule' in this.options) {
                for (var i = 0; i < this.options.schedule.length; i++) {
                  if (this.isEditMode) {
                    this.options.schedule[i]['start_date'] = moment(this.options.schedule[i]['start_date_zone']).local().format()
                    this.options.schedule[i]['end_date'] = moment(this.options.schedule[i]['end_date_zone']).local().format()
                  } else {
                    this.options.schedule[i]['start_date'] = null
                    this.options.schedule[i]['end_date'] = null
                  }
                }
              }
              this.options['start_date'] = this.options['start_date'] + 'T00:00:00.000Z'
              this.options['end_date'] = this.options['end_date'] + 'T00:00:00.000Z'
              this.selectedStatus = this.statusOption.filter((item) => item.value === this.options['active'])[0]
              this.classKeyword = this.options.kelas_nama
              this.initListExamUrl()
              this.initListPretestUrl()
              this.stillLoading = false
            } else {
              showVueToast(resp.data.message, 'success', 2000)
            }
            this.counterProgress = 100
            this.showProgress = false
          })
      } else {
        this.showProgress = false
        this.stillLoading = false
      }
    },
    validatorPhoneNumber(value, forError) {
      if (value.length >= 15) {
        switch (forError) {
          case 'userPhoneNumber':
            this.userPhoneNumber = value.slice(0, 15)
            break
          case 'customerServiceNumber':
            this.customerServiceNumber = value.slice(0, 15)
            break
        }
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value[0] !== '0' || value[1] !== '8') {
        this.errors[forError] = 'Must start with 08xxxx'
      } else if (value.length < 10) {
        this.errors[forError] = 'Minimum 10 digits, Maximum 15 digits'
      } else {
        this.errors[forError] = undefined
      }
    },
    allValidation() {
      let pass = true

      const error = isNotEmptyErrorForm(this.options)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})

      if (this.options.customerServiceNumber == '') {
        pass = true
      } else if (this.options.customerServiceNumber[0] !== '0' || this.options.customerServiceNumber[1] !== '8') {
        this.errors['customerServiceNumber'] = 'Must start with 08xxxx'
        pass = false
      } else if (this.options.customerServiceNumber.length < 10) {
        this.errors['customerServiceNumber'] = 'Minimum 10 digits, Maximum 15 digits'
        pass = false
      }
      return pass
    },
    submitData() {
      const scheduleError = []
      if (!this.allValidation()) return
      const customerId = this.clientId
      if (!this.options.kelas_id) {
        return showVueToast('Choose a class!!', 'error', 3000)
      }
      if (!this.options.nama) {
        return showVueToast("Schedule name can't be empty!", 'error', 3000)
      }

      if (!this.selectedInstructor) {
        return showVueToast('Choose an instructor!', 'error', 3000)
      }

      if (!this.selectedStatus) {
        return showVueToast('Choose a status!', 'error', 3000)
      }

      if (this.modeForm === 'Edit' || this.modeForm === 'Copy') {
        if (this.$route.meta.name === 'customer') {
          this.options.logo = this.profileLogo
        }
        if ('schedule' in this.options) {
          for (var i = 0; i < this.options.schedule.length; i++) {
            if (this.isEditMode) {
              this.options.schedule[i]['start_date'] = this.options.schedule[i]['start_date'].substring(0, 10) + 'T' + this.options.schedule[i]['start_date'].substring(11, 19) + 'Z'
              this.options.schedule[i]['end_date'] = this.options.schedule[i]['end_date'].substring(0, 10) + 'T' + this.options.schedule[i]['end_date'].substring(11, 19) + 'Z'
            } else {
              if (!this.options.schedule[i]['start_date'] || !this.options.schedule[i]['end_date']) {
                scheduleError.push(true)
              }
            }
          }
        }
        if (scheduleError.indexOf(true) > -1) {
          showVueToast("Schedule date can't be empty!", 'error', 3000)
          return
        }
        this.options['instructur_id'] = (this.selectedInstructor && this.selectedInstructor.id) || ''
        this.options['active'] = this.selectedStatus ? this.selectedStatus.value : ''
        this.options['pretest_exercise_id'] = (this.selectedPretest && this.selectedPretest.id) || ''
        this.options['exam_exercise_id'] = (this.selectedExam && this.selectedExam.id) || ''
        if (this.isEditMode) {
          this.$store
            .dispatch('jadwalkelas/UPDATE_JADWALKELAS', {
              masterType: this.$route.meta.name,
              masterId: this.idForm,
              customerId,
              payload: this.options
            })
            .then((resp) => {
              showVueToast('Successfully saved schedule!', 'success', 2000)
              if (resp.data.status === '200 OK') window.location.reload()
            })
            .catch((err) => {
              showVueToast('Failed to save schedule!', 'error', 2000)
            })
        } else {
          if (this.isCopyMode) {
            this.options.nama = `COPY - ${this.options.nama}`
          }
          this.$store
            .dispatch('jadwalkelas/CREATE_JADWALKELAS', {
              masterType: this.$route.meta.name,
              masterId: this.idForm,
              customerId,
              payload: this.options
            })
            .then((resp) => {
              if (this.isCopyMode) {
                showVueToast("You've successfully copy the schedule!", 'success', 2000)
              } else {
                showVueToast('Successfully saved schedule!', 'success', 2000)
              }
              if (resp.data.status === '200 OK') {
                if (this.customer.role_id > 2) {
                  this.initData()
                } else {
                  this.toLMSClassListMenu()
                }
              }
            })
            .catch(() => {
              showVueToast('Failed to save schedule!', 'error', 2000)
            })
        }
      } else {
        if ('schedule' in this.options) {
          for (var i = 0; i < this.options.schedule.length; i++) {
            this.options.schedule[i]['start_date'] = this.options.schedule[i]['start_date'].substring(0, 10) + 'T' + this.options.schedule[i]['start_date'].substring(11, 19) + 'Z'
            this.options.schedule[i]['end_date'] = this.options.schedule[i]['end_date'].substring(0, 10) + 'T' + this.options.schedule[i]['end_date'].substring(11, 19) + 'Z'
          }
        }

        this.options['instructur_id'] = (this.selectedInstructor && this.selectedInstructor.id) || ''
        this.options['active'] = this.selectedStatus ? this.selectedStatus.value : ''
        this.options['pretest_exercise_id'] = (this.selectedPretest && this.selectedPretest.id) || ''
        this.options['exam_exercise_id'] = (this.selectedExam && this.selectedExam.id) || ''

        this.$store
          .dispatch('jadwalkelas/CREATE_JADWALKELAS', {
            masterType: this.$route.meta.name,
            masterId: this.idForm,
            customerId,
            payload: this.options
          })
          .then((resp) => {
            showVueToast('Successfully saved schedule!', 'success', 2000)
            if (resp.data.status === '200 OK') {
              if (this.customer.role_id > 2) {
                this.initData()
              } else {
                this.toLMSClassListMenu()
              }
            }
          })
          .catch(() => {
            showVueToast('Failed!', 'error', 2000)
          })
      }
    },
    addSchedule: function () {
      this.options.schedule.push({
        id: 0,
        start_date: '',
        end_date: '',
        title: '',
        description: '',
        materi: ''
      })
    },
    deleteSchedule: function (indexSchecule) {
      this.options.schedule.splice(indexSchecule, 1)
    },
    addDocument: function () {
      this.options.document.push({ id: 0, name: '', file_url: '' })
    },
    deleteDocument: function (indexDocument) {
      this.options.document.splice(indexDocument, 1)
    },
    searchClass() {
      if (this.classKeyword !== '') {
        clearTimeout(this.debounce)
        let self = this
        this.debounce = setTimeout(() => {
          if (this.classKeyword !== '') self.getListKelas(self.classKeyword)
        }, 300)
      } else {
        this.isSearching = false
      }
    },
    selectClass(item) {
      this.options.kelas_id = item.id
      this.classKeyword = item.nama
      this.isSearching = false
      this.getListExercise()
      this.getListInstructur()
    },
    getListKelas(searchTerm = '') {
      this.isFetching = true
      this.list_kelas = []
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: 'name',
          name: searchTerm
        }
      })
      this.$store
        .dispatch('kelas/GET_LIST_KELAS', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId
        })
        .then(() => {
          let listKelasGetters = this.$store.getters['kelas/list_kelas'] ? this.$store.getters['kelas/list_kelas'] : []
          let forListKelas = []
          for (let i = 0; i < listKelasGetters.length; i++) {
            forListKelas.push(listKelasGetters[i])
          }
          this.list_kelas = forListKelas
          this.isSearching = true
          this.isFetching = false
        })
        .catch(function () {})
    },
    getListInstructur() {
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 100,
          sort: '',
          kelas_id: this.options.kelas_id
        }
      })
      this.$store
        .dispatch('instructur/GET_LIST_INSTRUCTUR', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId
        })
        .then(() => {
          let listInstructorGetters = this.$store.getters['instructur/list_instructur'] ? this.$store.getters['instructur/list_instructur'] : []
          let forListInstructor = []
          for (let i = 0; i < listInstructorGetters.length; i++) {
            forListInstructor.push(listInstructorGetters[i])
          }
          this.list_instructur = forListInstructor
          if (this.isEditMode || this.isCopyMode) {
            this.selectedInstructor = this.list_instructur.filter((item) => item.id === this.options['instructur_id'])[0]
          }
        })
        .catch(function () {})
    },
    dropzoneRemovedSuccess: function (file, response) {
      this.payload.jumlah_peserta_capture = ''
    },
    dropzoneLogoSuccess: function (file, response) {
      alert(response.data.url)
      this.options.jumlah_peserta_capture = response.data.url
    },
    initListExamUrl: function () {
      for (let i = this.list_kelas.length - 1; i >= 0; i--) {
        if (this.list_kelas[i].id === this.options.kelas_id) {
          this.listExamUrl = this.list_kelas[i].exam_url_array
          this.list_java_instructur_id = this.list_kelas[i].java_instructur_id_array
        }
      }
      this.initListPretestUrl()
      this.getListInstructur()
      this.getListExercise()
    },
    initListPretestUrl: function () {
      for (let i = this.list_kelas.length - 1; i >= 0; i--) {
        if (this.list_kelas[i].id === this.options.kelas_id) {
          this.listPretestUrl = this.list_kelas[i].pretest_url_array
        }
      }
    },
    getListExercise() {
      const customerId = this.clientId
      let paramsTemp = queryString.stringify({
        ...{
          limit: 10000,
          sort: '',
          kelas_id: this.options.kelas_id
        }
      })
      this.$store
        .dispatch('exercise/GET_LIST_EXERCISE', {
          masterType: this.$route.meta.name,
          params: paramsTemp,
          customerId
        })
        .then(() => {
          let listExerciseGetters = this.$store.getters['exercise/list_exercise'] ? this.$store.getters['exercise/list_exercise'] : []
          let forListExercise = []
          for (let i = 0; i < listExerciseGetters.length; i++) {
            forListExercise.push(listExerciseGetters[i])
          }
          let list_exercise = forListExercise
          this.list_exercise_pretest = []
          this.list_exercise_exam = []
          for (var j = 0; j < list_exercise.length; j++) {
            if (list_exercise[j].exercise_type_id === 1) {
              this.list_exercise_pretest.push(list_exercise[j])
            } else if (list_exercise[j].exercise_type_id === 3) {
              this.list_exercise_exam.push(list_exercise[j])
            }
          }
          if (this.isEditMode || this.isCopyMode) {
            this.selectedPretest = this.list_exercise_pretest.filter((item) => item.id === this.options.pretest_exercise_id)[0]
            this.selectedExam = this.list_exercise_exam.filter((item) => item.id === this.options.exam_exercise_id)[0]
          }
        })
        .catch(function () {})
    }
  },
  computed: {
    checkboxPercentage() {
      return this.editComponentSelected.isAverage
    },
    isScheduleEmpty() {
      if (!this.options['schedule']) return true
      return this.options['schedule'].length === 0
    },
    isAddLevelButtonEnable() {
      if (this.levelTabSelected === 'Summary' || this.grades.length === 0) return false
      if (this.levelTabSelected !== this.totalLevel) return false
      return true
    },
    isSummaryTabEnable() {
      if (this.latestGrades.length === 0 || this.latestGrades.length !== 1 || this.studentList.length === 0) return false
      if (this.latestGrades[0].scoringSchemaGroupItems.length === 0) return false
      return true
    },
    isSaveItemComponentEnable() {
      if (this.itemMode === 'New' && this.editComponentSelected.title !== '') return true
      if (!this.editComponentSelected.scoringSchemaGroupItems) return false
      const percentage = this.editComponentSelected.scoringSchemaGroupItems ? this.editComponentSelected.scoringSchemaGroupItems.map((item) => parseInt(item.percentage)) : []
      const titles = this.editComponentSelected.scoringSchemaGroupItems ? this.editComponentSelected.scoringSchemaGroupItems.map((item) => item.title) : []
      if (percentage.length === 0 || titles.length === 0) return false
      const reducer = (accumulator, curr) => accumulator + curr
      const totalPercentage = percentage.reduce(reducer)
      const isEmptyValueExist = percentage.includes(NaN)
      const isTitleEmptyExist = titles.includes('') || titles.includes(null)
      if (!isTitleEmptyExist && this.editComponentSelected.isAverage) return true
      if (totalPercentage === 100 && !isEmptyValueExist && !isTitleEmptyExist) return true
      return false
    },
    clientId() {
      return localStorage.getItem('client')
    },
    jadwalKelasId() {
      return this.$route.params.id
    },
    pageForPagination() {
      return this.currentPage + 1
    },
    pageForPaginationStudent() {
      return this.currentPageStudent + 1
    },
    isEditMode() {
      return this.modeForm === 'Edit'
    },
    isCopyMode() {
      return this.modeForm === 'Copy'
    },
    customerServiceNumber() {
      return this.options.customerServiceNumber
    }
  },
  watch: {
    checkboxPercentage(value) {
      if (this.editComponentSelected.scoringSchemaGroupItems.length !== 0) {
        this.editComponentSelected.scoringSchemaGroupItems = this.editComponentSelected.scoringSchemaGroupItems.map((item) => {
          return {
            ...item,
            percentage: ''
          }
        })
      }
    },
    levelTabSelected(value) {
      if (value === 1) {
        this.referenceTypeSelected = 'Free'
      } else {
        this.referenceTypeOptions = []
        this.referenceTypeOptions = [`Level ${this.levelTabSelected - 1}`, 'Free']
        this.referenceTypeSelected = `Level ${this.levelTabSelected - 1}`
      }
    },
    selectedProgramStudent(val) {
      if (val.value) {
        this.getPipelineStage()
      }
    },
    selectedSource(val) {
      if (val.value === 'MANUAL') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
    userFullName(value) {
      if (!value) {
        this.errors['userFullName'] = 'Name is required'
      } else {
        this.errors['userFullName'] = undefined
      }
    },
    userEmail(value) {
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (!value) {
        this.errors['userEmail'] = 'Email is required'
      } else if (!regex.test(value)) {
        this.errors['userEmail'] = 'Format is invalid. ex: user@mail.com'
      } else {
        this.errors['userEmail'] = undefined
      }
    },
    userPhoneNumber(value) {
      this.validatorPhoneNumber(value, 'userPhoneNumber')
    },
    customerServiceNumber(value) {
      this.validatorPhoneNumber(value, 'customerServiceNumber')
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    quillEditor,
    ArrowForward: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/ArrowForward'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label'),
    DataTable: () => import(/* webpackChunkName: "data-table" */ '@/components/Table/DataTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    Trash: () => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash'),
    Close: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Close'),
    Table: () => import(/* webpackChunkName: 'data-table' */ '@/components/Table/GeneralTable')
  }
}
</script>
<style scoped lang="scss">
.ql-editor ol li {
  display: list-item;
}
.time {
  &::v-deep .vdatetime-input {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
    padding: 12px;
    cursor: pointer;
    width: 100%;
  }
}
</style>
